<template>
  <div class="d-flex flex-column flex-grow-1">
    <!-- <div style="min-height: 52px" /> -->
    <document-in-tab>
      <v-card class="max-height mb-0 d-flex">
        <v-card-text class="d-flex flex-column">
          <v-text-field
            v-model="search"
            :prepend-inner-icon="$icon('i.Search')"
          />
          <v-switch
            v-model="showInactiveAccounts"
            class="fit-content"
            @change="fetch"
            :label="$t('t.ShowInactiveAccounts')"
          />
          <v-treeview
            :items="items"
            item-key="id"
            item-text="name"
            :open="opens"
            :search="search"
            :filter="filter"
            hoverable
          >
            <template v-slot:label="{ item }">
              <div
                class="d-flex align-center"
                :class="{
                  'clickable': item.type !== 'current' && item.hasAccess,
                  'font-weight-medium': item.type === 'current'
                }"
                @click="navigateItem(item)"
              >
                <v-icon
                  v-if="!item.hasAccess"
                  class='ma-1'
                >{{$icon('i.MinusCircle')}}</v-icon>
                <computed-document-ref
                  :id="item.id"
                  document-type='accounts'
                  :options="{hideNavigation: !item.hasAccess}"
                />
                <span class="mx-2">-</span>
                <business-division-ref
                  :id="item.businessDivisionId"
                  :company-props="{visible: true, hideIfSameAsDivision: true}"
                  class="mx-2"
                  :show-icon="false"
                />
                <span class="mx-1">-</span>
                <span class="mx-1">{{item.businessIdentifier}}</span>
                <span class="mx-1">-</span>
                <computed-document-ref
                  class="mx-2"
                  :id="item.workQueueId"
                  document-type='work-queues'
                  :options="{hideNavigation: true}"
                />
                -
                <amount
                  class="ml-2"
                  :amount="item.balance.value"
                  :currency="item.currency"
                />
              </div>
            </template>
          </v-treeview>
        </v-card-text>
      </v-card>
    </document-in-tab>
  </div>
</template>

<script>
export default {
  components: {
    Amount: () => import('@/components/amount'),
    BusinessDivisionRef: () => import('@/components/documents-ref/business-division-ref'),
    computedDocumentRef: () => import('@/components/documents-ref/computed-document-ref'),
    DocumentInTab: () => import('@/components/document-in-tab')
  },
  computed: {
    filter () {
      return (item, search) => {
        search = search.toLowerCase()
        return (item.name ?? '').toLowerCase().includes(search) ||
          (item.number ?? '').toLowerCase().includes(search) ||
          (item.businessIdentifier ?? '').toLowerCase().includes(search)
      }
    },
    itemCount () {
      return this.flatten(this.items).length
    }
  },
  data () {
    return {
      accountId: this.$route.params.id,
      items: [],
      opens: [],
      search: null,
      showInactiveAccounts: false
    }
  },
  methods: {
    computeOpens (node, array) {
      array.push(node.id)
      node.children.map(children => this.computeOpens(children, array))
    },
    async fetch () {
      const e = await this.$http().get(`/core/v6/accounts/${this.accountId}/hierarchy/${this.showInactiveAccounts}`)
      const opens = []
      await this.computeOpens(e.data, opens)
      this.opens = opens
      this.items = [e.data]
    },
    flatten (item) {
      return item.flatMap(i => [i.id, ...this.flatten(i.children)])
    },
    getClasses (item) {
      const array = [item.type]
      if (item.hasAccess && item.type !== 'current') {
        array.push('clickable')
      }
      return array
    },
    navigateItem (item) {
      if (item.hasAccess && item.type !== 'current') {
        this.$router.push({ path: '/accounts/' + item.id }).catch(_ => { })
      }
    }
  },
  mounted () {
    this.fetch()
  }
}
</script>

<style lang="stylus" scoped>
.max-height
  max-height 100%

.v-text-field
  flex-grow 0
  align-self start
  width 300px

.current
  font-weight bold

.v-treeview
  overflow auto
  flex-grow 1

.fit-content
  width fit-content
</style>
